import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Service from "../components/services";
import Content, { HTMLContent } from "../components/Content";

export const IndexPageTemplate = ({
  clientsLogos,
  content,
  contentComponent,
  description,
  details,
  herodescription,
  image,
  title,
  video,
}) => {
  const ServiceContent = contentComponent || Content;

  return (
    <Service
      title={title}
      image={image}
      description={description}
      details={details}
      ServiceContent={ServiceContent}
      content={content}
      video={video}
      herodescription={herodescription}
      clientsLogos={clientsLogos}
    />
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  slider: PropTypes.array,
  client: PropTypes.string
};

const IndexPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        content={html}
        video={frontmatter.video}
        contentComponent={HTMLContent}
        description={frontmatter.description}
        image={frontmatter.image}
        details={frontmatter.details}
        herodescription={frontmatter.herodescription}
        clientsLogos={frontmatter.clientsLogos}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query ServicesPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        herodescription
        description
        video
        details {
          detail
        }
        clientsLogos{
          logo {
            publicURL
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
