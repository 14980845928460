import React from "react";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { Container, UnderLine, Button } from "../global-style";
import TbwaBullets from "../common/TbwaBullets";
import useProjectsdata from "../SiteProjects";
import { Link } from "gatsby";
import Helmet from 'react-helmet';

const HeroContent = styled.div`
  color: white;
  padding: 3rem 0;
  & > * {
    max-width: 20rem;
  }
  @media (max-width: 800px) {
    h1 {
      font-size: 1.2rem;
    }
  }
`;
const ProjectCard = styled.div`
  color: black;
  width: 350px;
  margin: 2rem 0;
  span{
    max-width: 10rem;
    color: var(--tbwa-yellow);
  }
  .logo {
    width: 100px;
  }

`;
const ProjectCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .overlay {
    min-height: 20rem;
    padding: 1rem;
    color: white;
    opacity: 0;
    display: flex;
    flex-direction: column;
    background: var(--tbwa-overlay);
    justify-content: center;
    align-items: flex-start;
  }
  .overlay:hover {
    opacity: 1;
  }
  justify-content: space-between;
`;
const LogosGrid = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 100px;
    margin-right: 1rem;
  }
`;
const IframeContainer = styled.div`
  height: 50vh;
  width: 100%;
  position: relative;
  margin: 1rem 0;
  iframe {
    position: absolute;
    border: unset;
    box-shadow: 1px 1px 5px var(--tbwa-overlay);
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
`;
const Service = ({
  image,
  details,
  herodescription,
  video,
  clientsLogos,
  title,
  ServiceContent,
  content
}) => {
  const filteredPojects = useProjectsdata().filter(item =>
    item.node.frontmatter.services?.includes(title)
  );

  const servicesLogos = clientsLogos ? clientsLogos.map((item) => item.logo.publicURL) : filteredPojects.map((item) => item.node.frontmatter.logo.publicURL);
  const data = details.map(item => item.detail);

  return (
    <>
     <Helmet>
            <title>{title}</title>
            <meta name="description" content={herodescription} />
            <meta property="og:title" content={title} data-react-helmet="true" />
            <meta
              property="og:image"
              content={image.childImageSharp.fluid.src}
              data-react-helmet="true"
            />
            <meta
              property="og:description"
              content={herodescription}
              data-react-helmet="true"
            />
          </Helmet>
      <BackgroundImage fluid={image.childImageSharp.fluid}>
        <Container>
          <HeroContent>
            <h1> {title}</h1>
            <p>{herodescription}</p>
            <UnderLine />
            <LogosGrid>
              {servicesLogos.map(logo => (
                <img src={logo} />
              ))}
            </LogosGrid>
          </HeroContent>
        </Container>
      </BackgroundImage>
      <Container>
        <ServiceContent content={content} />
        <TbwaBullets data={data} />

        <RelatedProjects projects={filteredPojects} />
        {video && (
          <IframeContainer>
            <iframe width="100%" height="100%" src={video} />
          </IframeContainer>
        )}
        <Button>
          <Link to="/contact">Contacter un conseiller</Link>
        </Button>
      </Container>
    </>
  );
};

const RelatedProjects = props => {
  const { projects } = props;
  return (
    <>
      {projects.length > 0 && (
        <>
          <h1>Realisations</h1>
          <UnderLine />
        </>
      )}
      <ProjectCardContainer>
        {projects.map((project, index) => {
          return (
            <div key={index}>
              <ProjectCard>
                <Link to={project.node.fields.slug}>
                  <BackgroundImage
                    fluid={project.node.frontmatter.image.childImageSharp.fluid}
                  >
                    <div className="overlay">
                      <img
                        className="logo"
                        src={project.node.frontmatter.logo.publicURL}
                      />
                      <span>{project.node.frontmatter.title}</span>
                      <p>{project.node.frontmatter.client}</p>
                    </div>
                  </BackgroundImage>
                </Link>
              </ProjectCard>
            </div>
          );
        })}
      </ProjectCardContainer>
    </>
  );
};

export default Service;
